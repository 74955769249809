<template>
  <div id="files">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3" class="py-0" v-if="!consents">
          <v-text-field
            outlined
            dense
            v-model="search"
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <template v-if="backAppointment">
          <v-btn
            v-if="$vuetify.breakpoint.xs"
            color="primary"
            @click.native="$router.back()"
            outlined
            fab
            class=""
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            @click.native="$router.back()"
            style="height: 30px"
            outlined
            class="ml-1 mt-2 mt-md-0 ml-md-3"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>
        </template>
        <v-btn
          @click="backDirectory"
          elevation="0"
          style="height: 41px; width: 41px"
          v-else-if="!isBase"
          fab
        >
          <v-icon style="margin-top: 2px" medium>mdi-arrow-left</v-icon>
        </v-btn>

        <create-folder
          :route="route"
          @update="save"
          v-if="selected.length === 0"
        />
        <upload-file
          :route="route"
          @update="save"
          v-if="selected.length === 0"
        />
        <upload-file
          :route="route"
          @update="save"
          type="camera"
          v-if="selected.length === 0 && $vuetify.breakpoint.smAndDown"
        />
        <template v-if="!backAppointment">
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            @click="switchConsents"
            style="
                min-width: 10px !important;
                padding-right: 2px !important;
                padding-left: 14px !important;
               
              "
            x-small
            fab
            elevation="0"
            class="mx-2"
            :outlined="!consents"
          >
            <v-icon small left>$form</v-icon>
          </v-btn>
          <v-btn
            v-else
            elevation="0"
            @click="switchConsents"
            :outlined="!consents"
            style="
              min-width: 10px !important;
              height: 30px;
              width: 210px;"
            class="mx-2"
          >
            <v-icon small left>$form</v-icon>
            {{ $t("show_consent_form") }}
            <v-btn
              icon
              color="black"
              @click.stop="switchConsents"
              v-if="consents"
              class="internal-btn"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-btn>
        </template>

        <v-btn
          v-if="consents && $vuetify.breakpoint.smAndDown"
          @click="exportConsents"
          style="
                min-width: 10px !important;
                padding-right: 2px !important;
                padding-left: 14px !important;
                height: 40px;
                width: 40px;
              "
          class="mx-2"
          elevation="0"
        >
          <v-icon small left>$download</v-icon>
        </v-btn>
        <v-btn
          @click="exportConsents"
          elevation="0"
          v-else-if="consents && $vuetify.breakpoint.mdAndUp"
          style="
            min-width: 10px !important;
            height: 30px;
            width: 150px;"
          class="mx-2"
        >
          <v-icon small left>$download</v-icon>
          {{ $t("download") }}
        </v-btn>

        <!--
          <take-foto
            :route="route"
            @update="save"
            v-if="selected.length === 0"
          />-->
        <rename ref="rename" :route="route" @update="save" />
        <move-file ref="moveFile" :route="route" @update="save" />
        <copy-file ref="copyFile" :route="route" @update="save" />

        <v-btn
          @click="del(selected)"
          v-if="selected.length !== 0"
          icon
          outlined
          style="height: 25px"
        >
          <v-icon size="18">$delete</v-icon>
        </v-btn>
        <!--
          <v-btn
            icon
            outlined
            style="height: 25px"
            class="ma-1"
            v-if="selected.length !== 0"
          >
            <v-icon size="18">$copy</v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            style="height: 25px"
            class="ma-1"
            v-if="selected.length !== 0"
          >
            <v-icon size="18">$move</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined class="ma-1" elevation="0"
                >Acciones</v-btn
              >
            </template>
            <v-list>
              <v-list-item class="fileList" @click="del(selected)">
                <v-icon class="mr-3" size="18">$delete</v-icon
                >Eliminar</v-list-item
              >
              <v-list-item class="fileList" 
                ><v-icon class="mr-3" size="18">$copy</v-icon
                >Copiar</v-list-item
              >
              <v-list-item class="fileList" 
                ><v-icon class="mr-3" size="18">$move</v-icon>Mover</v-list-item
              >
            </v-list>
          </v-menu>
          -->

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-3 my-0"
              fab
              x-small
              v-on="on"
              elevation="0"
              :outlined="displayCards !== 'cards'"
              @click="displayCards = 'cards'"
            >
              <v-icon size="15">$square</v-icon>
            </v-btn>
          </template>
          <span>Cards</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              value="list"
              class="my-0"
              fab
              x-small
              elevation="0"
              :outlined="displayCards !== 'list'"
              @click="displayCards = 'list'"
              v-on="on"
            >
              <v-icon size="15">$list</v-icon>
            </v-btn>
          </template>
          <span>Tabla</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
    <v-card-subtitle
      style="color: var(--v-primary-base); font-size: 16px"
      class="pt-0"
    >
      <v-row align="center" class="py-0">
        <v-avatar size="36" class="mr-2 ml-3">
          <img :src="$store.getters['auth/userImage']" alt="John" />
        </v-avatar>
        <v-col
          class="no-wrap"
          :cols="
            consents
              ? $vuetify.breakpoint.smAndUp
                ? 1
                : 6
              : $vuetify.breakpoint.smAndUp
              ? 1
              : 6
          "
        >
          <template v-for="(r, i) in folders">
            <span
              :key="`span-${i}`"
              @click="goTo(i)"
              style="cursor: pointer; text-transform: uppercase"
              >{{ r.name }}</span
            >
            <span v-if="i < folders.length - 1" :key="`separator-${i}`">
              >
            </span>
          </template>
        </v-col>
        <template v-if="consents">
          <v-col cols="6" sm="2" class="py-0">
            <v-menu
              v-model="menu.first_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="daymenu"
                  outlined
                  dense
                  :value="from ? $d(new Date(from), 'date2digits') : null"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-bind:class="{ 'error--text': from > to }"
                  v-on="on"
                  clearable
                  :label="$t('from')"
                  @click:clear="
                    from = null;
                    searchFilter();
                  "
                >
                  <template v-slot:prepend-inner
                    ><v-icon small class="mt-1">$appointments</v-icon></template
                  ></v-text-field
                >
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="from"
                color="primary"
                no-title
                @input="
                  menu.first_day = false;
                  searchFilter();
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="2" class="py-0">
            <v-menu
              v-model="menu.last_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="daymenu"
                  outlined
                  dense
                  :value="to ? $d(new Date(to), 'date2digits') : null"
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  v-bind:class="{ 'error--text': from > to }"
                  :label="$t('to')"
                  clearable
                  @click:clear="
                    to = null;
                    searchFilter();
                  "
                >
                  <template v-slot:prepend-inner
                    ><v-icon small class="mt-1">$appointments</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="to"
                color="primary"
                :min="from"
                no-title
                @input="
                  menu.last_day = false;
                  searchFilter();
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="2" class="py-0">
            <v-autocomplete
              outlined
              hide-details
              dense
              v-model="customer_id"
              :items="customers"
              item-value="id"
              :search-input.sync="searchCustomer"
              :loading="isLoadingCustomer"
              cache-items
              item-text="user.fullname"
              @change="searchFilter()"
              :label="$tc('customer')"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            sm="2"
            class="py-0"
            v-if="$store.getters['auth/isStudio']"
          >
            <v-autocomplete
              outlined
              hide-details
              dense
              v-model="tattooer_id"
              :items="tattooers"
              item-value="id"
              :item-text="
                (x) =>
                  `${x.user.first_name} ${
                    x.user.last_name ? x.user.last_name : ''
                  }`
              "
              @change="searchFilter()"
              :label="$tc('artist')"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" class="py-0">
            <v-chip color="primary" outlined>
              {{ $t("info") }}
              <v-menu
                v-if="!$vuetify.breakpoint.mdAndDown"
                bottom
                :max-width="450"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="$vuetify.breakpoint.smAndDown ? '' : on"
                    color="primary"
                    class="ma-2"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>{{
                      $t("consent_explanation")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-chip>
          </v-col>
        </template>
      </v-row>
    </v-card-subtitle>

    <!-- inicio cards-->
    <div v-if="this.displayCards == 'cards'">
      <v-row
        :class="
          $vuetify.breakpoint.smAndDown && directoriesFiltered.length % 2 == 0
            ? 'center'
            : 'left'
        "
      >
        <v-col
          v-for="(dir, i) in directoriesFiltered"
          :key="`dir-${i}`"
          cols="6"
          md="3"
          class="px-1 px-md-2"
        >
          <base-contact-card
            :title="dir.name"
            :email="dir.email"
            :phone="dir.phone ? dir.phone : null"
            @card_clicked="enterDirectory(dir)"
          >
            <template slot="actions">
              <v-menu offset-y v-if="!dir.email">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" large
                    >mdi-dots-horizontal</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item class="fileList" @click="move(dir.name)"
                    ><v-icon class="mr-3" small>$move</v-icon>
                    {{ $t("move") }}</v-list-item
                  >
                  <v-list-item class="fileList" @click="copy(dir.name)"
                    ><v-icon class="mr-3" small>$copy</v-icon>
                    {{ $t("copy") }}</v-list-item
                  >
                  <v-list-item class="fileList" @click="rename(dir.name)"
                    ><v-icon class="mr-3" small>$editprofile</v-icon
                    >{{ $t("editName") }}</v-list-item
                  >
                  <v-list-item class="fileList" @click="del([dir.name])">
                    <v-icon class="mr-3" small>$delete</v-icon
                    >{{ $tc("delete") }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
            <template slot="checkbox">
              <v-checkbox
                v-model="selected"
                multiple
                on-icon="mdi-check-circle"
                off-icon="mdi-circle-outline"
                :value="dir.name"
              ></v-checkbox>
            </template>
          </base-contact-card>
          <!--
              <v-list-item-subtitle class="pt-3 pl-6 pr-6">
                <a
                  @click.stop="
                    $router.push({
                      name: 'email',
                      params: { email: dir.email },
                    })
                  "
                  v-text="dir.email"
                />
              </v-list-item-subtitle>
              -->
        </v-col>
      </v-row>
      <!--fin directorios-->
      <!--inicio files-->
      <v-row :class="filesFiltered.length % 2 == 0 ? 'center' : 'right'">
        <v-col
          v-for="(file, i) in filesFiltered"
          :key="`file-${i}`"
          cols="5"
          sm="4"
          md="3"
          class="px-1 px-md-2"
        >
          <v-card style="width: 15em; height: 20em;">
            <v-row justify="end">
              <v-col cols="5">
                <v-checkbox
                  style="position: absolute; left: 5px; top: 5px"
                  v-model="selected"
                  multiple
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  :value="file.name"
                ></v-checkbox>
              </v-col>
              <v-col cols="5" md="4" class="pl-4 pt-0 pb-0">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon style="color: #afafaf" size="30" large
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      class="fileList"
                      @click="
                        $router.push({
                          name: 'appointmentView',
                          params: { id: file.appointment_id },
                        })
                      "
                      v-if="file.appointment_id"
                    >
                      <v-icon class="mr-3" small>$eye</v-icon
                      >{{ $t("appointments.see") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="viewFile(file)"
                      ><v-icon class="mr-3" small>$form</v-icon
                      >{{ $t("consent.see") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="rename(file.name)"
                      ><v-icon class="mr-3" small>$editprofile</v-icon
                      >{{ $t("editName") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="del([file.name])">
                      <v-icon class="mr-3" small>$delete</v-icon
                      >{{ $tc("delete") }}</v-list-item
                    >
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              style="text-align: center"
              @click="fileType(file) === 'zip' ? null : viewFile(file)"
            >
              <v-row justify="center">
                <v-col v-if="fileType(file) === 'pdf'" cols="9">
                  <pdf
                    :src="file.prev"
                    :page="1"
                    class="scrollable pdf_preview"
                  >
                    <template slot="loading">loading content here...</template>
                  </pdf>
                </v-col>
                <v-col
                  cols="12"
                  v-else-if="fileType(file) === 'img'"
                  align="center"
                >
                  <v-img
                    :src="file.prev"
                    :style="
                      $vuetify.breakpoint.smAndDown
                        ? 'max-width: 80px'
                        : 'width: 13em;height: 10em'
                    "
                  />
                </v-col>
                <!--  <v-col cols="12" v-else-if="fileType(file) === 'office'">
                  <VueDocPreview
                    :value="file.prev"
                    :type="fileType(file)"
                    :height="60"
                    >other previer</VueDocPreview
                  >
                </v-col>-->
                <v-col v-else cols="12"
                  ><v-icon size="100">mdi-file-outline</v-icon></v-col
                >
              </v-row>
              <v-list-item-title class="pl-3 pr-3">{{
                clearName(file)
              }}</v-list-item-title>
              <v-list-item-title
                class="pl-3 pr-3 success--text text-uppercase"
                >{{
                  file.state ? $tc("appointments.states." + file.state) : ""
                }}</v-list-item-title
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!--fin files, fin cards-->

    <div v-else>
      <div v-if="!$vuetify.breakpoint.xsOnly">
        <v-row
          v-for="(dir, i) in directoriesFiltered"
          :key="`dir-${i}`"
          class="ml-4 mb-0 mt-0 mb-3 pr-5"
          style="width: 100%; height: 55px; margin-bottom: 0px"
        >
          <v-card
            style="width: 100%; height: 55px; margin-bottom: 0px"
            class="ma-0"
            elevation="6"
          >
            <v-row>
              <v-col class="pt-0 pb-0" cols="1" style="max-width: 30px">
                <v-checkbox
                  class="mt-3 ml-3"
                  v-model="selected"
                  multiple
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  :value="dir.name"
                ></v-checkbox>
              </v-col>
              <v-col cols="1" class="pt-0 pb-0 pl-6">
                <v-row class="pt-2">
                  <v-avatar
                    @click="enterDirectory(dir)"
                    color="primary"
                    size="40"
                    style="
                      margin: auto;
                      font-size: 20px;
                      text-transform: uppercase;
                      color: #272727;
                      cursor: pointer;
                    "
                    >{{ nameAvatar(dir) }}</v-avatar
                  >
                </v-row>
              </v-col>
              <v-col cols="3" md="3" class="pb-0 pt-2">
                <v-list-item-title
                  @click="enterDirectory(dir)"
                  style="
                    color: var(--v-primary-base);
                    font-size: 15px;
                    padding-top: 9px;
                    text-transform: uppercase;
                    cursor: pointer;
                  "
                  >{{ clearName(dir) }}</v-list-item-title
                >
              </v-col>
              <v-col cols="4" class="pb-0 pt-1">
                <v-list-item-title
                  class="ml-9"
                  style="font-size: 12px; padding-top: 14px"
                >
                  <a
                    @click.stop="
                      $router.push({
                        name: 'email',
                        params: { email: dir.email },
                      })
                    "
                    v-text="dir.email"
                  />
                </v-list-item-title>
              </v-col>
              <v-col class="pa-0 pt-2">
                <v-row justify="end">
                  <v-list-item-action class="pb-5 mt-0">
                    <v-menu offset-y v-if="!dir.email">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon class="mr-3">
                          <v-icon style="color: #afafaf" size="30" large
                            >mdi-dots-horizontal</v-icon
                          >
                        </v-btn>
                      </template>
                      <!-- LISTA PRINCIPAL-->
                      <v-list>
                        <v-list-item class="fileList" @click="move(dir.name)"
                          ><v-icon class="mr-3" small>$move</v-icon>
                          {{ $t("move") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="copy(dir.name)"
                          ><v-icon class="mr-3" small>$copy</v-icon>
                          {{ $t("copy") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="rename(dir.name)"
                          ><v-icon class="mr-3" small>$editprofile</v-icon
                          >{{ $t("editName") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="del([dir.name])">
                          <v-icon class="mr-3" small>$delete</v-icon
                          >{{ $tc("delete") }}</v-list-item
                        >
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-row>
              </v-col>
            </v-row>
          </v-card> </v-row
        ><v-row
          v-for="(file, i) in filesFiltered"
          :key="`file-${i}`"
          class="ml-4 mb-0 mt-0 mb-3 pr-5"
          style="width: 100%; height: 55px; margin-bottom: 0px"
        >
          <v-card
            style="width: 100%; height: 55px; margin-bottom: 0px"
            class="ma-0"
            elevation="6"
          >
            <v-row align="center">
              <v-col class="pt-0 pb-0" cols="1" style="max-width: 30px">
                <v-checkbox
                  class="mt-3 ml-3"
                  v-model="selected"
                  multiple
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  :value="file.name"
                ></v-checkbox>
              </v-col>
              <v-col cols="7" class="py-0 pl-8">
                <v-list-item-title
                  @click="fileType(file) === 'zip' ? null : viewFile(file)"
                  class="pl-3"
                  style="cursor: pointer"
                  >{{ clearName(file) }}</v-list-item-title
                >
              </v-col>
              <v-col class="success--text text-uppercase">
                {{ file.state ? $tc("appointments.states." + file.state) : "" }}
              </v-col>

              <v-col class="pa-0 pt-1">
                <v-row justify="end" align="center" class="mr-4">
                  <v-tooltip bottom v-if="fileType(file) === 'zip'">
                    <template v-slot:activator="{ on }">
                      <a
                        v-on="on"
                        :href="file.prev"
                        download
                        target="_blank"
                        class="button mt-1"
                      >
                        <v-icon color="primary">$download</v-icon>
                      </a>
                    </template>
                    {{ $t("download") }}
                  </v-tooltip>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon class="mr-3">
                        <v-icon style="color: #afafaf" size="30" large
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <!--LISTA F.CONSENTIMIENTO-->
                    <v-list>
                      <v-list-item
                        class="fileList"
                        @click="
                          $router.push({
                            name: 'appointmentView',
                            params: { id: file.appointment_id },
                          })
                        "
                        v-if="file.appointment_id"
                      >
                        <v-icon class="mr-3" small>$eye</v-icon
                        >{{ $t("appointments.see") }}</v-list-item
                      >
                      <v-list-item class="fileList" @click="viewFile(file)"
                        ><v-icon class="mr-3" small>$form</v-icon
                        >{{ $t("consent.see") }}</v-list-item
                      >
                      <v-list-item class="fileList" @click="rename(file.name)"
                        ><v-icon class="mr-3" small>$editprofile</v-icon
                        >{{ $t("editName") }}</v-list-item
                      >
                      <v-list-item class="fileList" @click="del([file.name])">
                        <v-icon class="mr-3" small>$delete</v-icon
                        >{{ $tc("delete") }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </div>
      <!--Parte movil de list-->
      <div v-if="$vuetify.breakpoint.xsOnly">
        <v-row
          v-for="(dir, i) in directoriesFiltered"
          :key="`dir-${i}`"
          class="ml-4 mb-0 mt-0 mb-3 pr-5"
          style="width: 100%; height: 55px; margin-bottom: 0px"
        >
          <v-card
            style="width: 100%; height: 55px; margin-bottom: 0px"
            class="ma-0"
            elevation="6"
          >
            <v-row class="ma-0">
              <v-col class="pt-0 pb-0" cols="1">
                <v-checkbox
                  class="mt-4 ml-0"
                  v-model="selected"
                  multiple
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  :value="dir.name"
                ></v-checkbox>
              </v-col>
              <v-col cols="2" class="pt-0 pb-0 pl-6 mr-5">
                <v-row class="pt-2">
                  <v-avatar
                    color="primary"
                    size="40"
                    style="
                      margin: auto;
                      font-size: 20px;
                      text-transform: uppercase;
                      color: #272727;
                      cursor: pointer;
                    "
                    >{{ nameAvatar(dir) }}</v-avatar
                  >
                </v-row>
              </v-col>
              <v-col cols="5" class="pb-0 py-0 pt-2">
                <v-list-item-title
                  @click="enterDirectory(dir)"
                  style="
                    color: var(--v-primary-base);
                    font-size: 15px;
                    padding-top: 9px;
                    text-transform: uppercase;
                    cursor: pointer;
                  "
                  >{{ clearName(dir) }}</v-list-item-title
                >
              </v-col>
              <!--<v-col cols="4" class="pb-0 pt-1">
                <v-list-item-title
                  class="ml-9"
                  style="font-size: 12px; padding-top: 14px"
                >
                  email del cliente 
                </v-list-item-title>
              </v-col>-->
              <v-col class="pa-0 pt-2">
                <v-row justify="end">
                  <v-list-item-action class="pb-5 mt-0 mr-3">
                    <v-menu offset-y v-if="!dir.email">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon class="mr-3">
                          <v-icon style="color: #afafaf" size="30" large
                            >mdi-dots-horizontal</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          class="fileList"
                          @click="
                            $router.push({
                              name: 'appointmentView',
                              params: { id: file.appointment_id },
                            })
                          "
                          v-if="file.appointment_id"
                        >
                          <v-icon class="mr-3" small>$eye</v-icon
                          >{{ $t("appointments.see") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="viewFile(file)"
                          ><v-icon class="mr-3" small>$form</v-icon
                          >{{ $t("consent.see") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="rename(file.name)"
                          ><v-icon class="mr-3" small>$editprofile</v-icon
                          >{{ $t("editName") }}</v-list-item
                        >
                        <v-list-item class="fileList" @click="del([file.name])">
                          <v-icon class="mr-3" small>$delete</v-icon
                          >{{ $tc("delete") }}</v-list-item
                        >
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-row>
              </v-col>
            </v-row>
          </v-card> </v-row
        ><v-row
          v-for="(file, i) in filesFiltered"
          :key="`file-${i}`"
          class="ml-4 mb-0 mt-0 mb-3 pr-5"
          style="width: 100%; height: 55px; margin-bottom: 0px"
        >
          <v-card
            style="width: 100%; height: 55px; margin-bottom: 0px"
            class="ma-0"
            elevation="6"
          >
            <v-row>
              <v-col class="pt-0 pb-0" cols="1" style="max-width: 30px">
                <v-checkbox
                  class="mt-4 ml-3"
                  v-model="selected"
                  multiple
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                  :value="file.name"
                ></v-checkbox>
              </v-col>
              <v-col cols="5" class="pb-0 pl-2 pr-0" style="padding-top: 18px">
                <v-list-item-title
                  @click="fileType(file) === 'zip' ? null : viewFile(file)"
                  class="pl-3"
                  style="cursor: pointer"
                  >{{ clearName(file) }}</v-list-item-title
                >
              </v-col>
              <v-col
                cols=""
                class="pb-0 pl-0 pr-0 success--text text-uppercase"
                style="padding-top: 18px"
              >
                {{ file.state ? $tc("appointments.states." + file.state) : "" }}
              </v-col>

              <v-col cols="2" class="pl-0 pa-0 pt-2" style="text-align: end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      :href="file.prev"
                      download
                      target="_blank"
                      class="button"
                      style="right: 50px; position: absolute; top: 15px"
                    >
                      <v-icon color="primary" small>$download</v-icon>
                    </a>
                  </template>
                  {{ $t("download") }}
                </v-tooltip>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="mr-6">
                      <v-icon style="color: #ffffff" size="30" large
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="fileList" @click="move(file.name)"
                      ><v-icon class="mr-3" size="18">$move</v-icon>
                      {{ $t("move") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="copy(file.name)"
                      ><v-icon class="mr-3" size="18">$copy</v-icon>
                      {{ $t("copy") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="rename(file.name)"
                      ><v-icon class="mr-3" size="18">$edit</v-icon
                      >{{ $t("editName") }}</v-list-item
                    >
                    <v-list-item class="fileList" @click="del([file])">
                      <v-icon class="mr-3" size="15">$delete</v-icon
                      >{{ $tc("delete") }}</v-list-item
                    >
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </div>
    </div>
    <div></div>

    <!--  <v-simple-table>
      <tbody>
        <tr v-for="(file, i) in files" :key="`file-${i}`">
          <td width="10px" class="pl-7">
            <v-icon color="primary" style="margin-top: 0.5em"
              >mdi-file-outline</v-icon
            >
          </td>
          <td width="1000px">
            <v-list-item-title @click="fileType(file) === 'zip' ? null : viewFile(file)" class="pl-3">{{
              clearName(file)
            }}</v-list-item-title>
          </td>
          <td class="pr-0">
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon style="color: #ffffff"large >mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="del([file])">Borrar</v-list-item>
                  <v-list-item @click="rename(file)"
                    >Cambiar nombre</v-list-item
                  >
                  <v-list-item @click="copy(file)">Copiar</v-list-item>
                  <v-list-item @click="move(file)">Mover</v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </td>
        </tr>
      </tbody>
    </v-simple-table>-->
    <!--fin vista lista-->
    <v-dialog
      v-model="fileDialog"
      :max-width="!$vuetify.breakpoint.xsOnly ? '70%' : ''"
    >
      <v-card v-if="selectedFile">
        <v-btn
          style="right: 0px; position: absolute; top: 0px"
          icon
          small
          @click="fileDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="pt-0" v-if="fileType(selectedFile) !== 'office'">
          <v-row justify="center" align="baseline">
            <v-col
              style="font-size: 24px !important; font-weight: 500 !important"
              :style="$vuetify.breakpoint.xsOnly ? 'font-size: 15px' : ''"
              cols="12"
              >{{ $t("file-viewer") }}</v-col
            >

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  :href="selectedFile"
                  download
                  target="_blank"
                  class="button"
                  style="right: 20px; position: absolute; top: 0px"
                >
                  <v-icon color="primary">$download</v-icon>
                </a>
              </template>
              {{ $t("download") }}
            </v-tooltip>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row justify="center">
            <v-col v-if="fileType(selectedFile) === 'pdf'" cols="9">
              <pdf
                :src="selectedFile"
                @num-pages="pageCount = $event"
                :page="currentPage"
                class="scrollable"
              >
                <template slot="loading">loading content here...</template>
              </pdf>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
              ></v-pagination>
            </v-col>
            <v-col cols="9" v-else-if="fileType(selectedFile) === 'img'">
              <v-img
                :src="selectedFile"
                max-width="100%"
                style="margin: auto"
              />
            </v-col>
            <v-col cols="12" v-else-if="fileType(selectedFile) === 'office'">
              <VueDocPreview
                :value="selectedFile"
                :type="fileType(selectedFile)"
                :height="70"
                >other previer</VueDocPreview
              >
            </v-col>

            <v-icon
              v-else-if="fileType(selectedFile) === 'zip'"
              size="200"
              color="grey1"
            >
              mdi-folder-zip-outline
            </v-icon>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
export default {
  name: "FileSystem",
  components: {
    VueDocPreview: () => import("vue-doc-preview"),
    pdf: () => import("vue-pdf"),
    UploadFile: () => import("./UploadFile"),
    Rename: () => import("./Rename"),
    MoveFile: () => import("./MoveFile"),
    CopyFile: () => import("./CopyFile"),
    CreateFolder: () => import("./CreateFolder"),
    TakeFoto: () => import("./TakeFoto"),
  },
  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },
  data() {
    return {
      isLoadingCustomer: false,
      searchCustomer: "",
      consents: false,
      route: null,
      directories: [],
      files: [],
      isBase: true,
      fileDialog: false,
      selectedFile: null,
      selected: [],
      displayCards: "list",
      filesPrev: [],
      pageCount: null,
      currentPage: 1,
      auto_enter: null,
      backAppointment: false,
      search: null,
      from: null,
      to: null,
      menu: {
        first_day: false,
        last_day: false,
      },
      customers: [],
      tattooers: [],
      tattooer_id: null,
      customer_id: null,
      state: null,
      states: ["complete", "customer"],
      type: "internal",
      folder_id: null,
      folders: [],
    };
  },
  methods: {
    ...mapActions("files", [
      "getCurrentRoute",
      "getConsents",
      "getExportConsents",
      "getSelectedFile",
      "delSelected",
    ]),
    fetchCurrentRoute() {
      this.getCurrentRoute({
        filters: {
          s3_dir: this.route,
          type: this.type,
          folder_id: this.folder_id,
        },
      }).then((data) => {
        this.save(data);
        if (this.auto_enter) {
          this.route += "/" + this.auto_enter;
          this.auto_enter = null;
          this.fetchCurrentRoute();
        }
      });
    },
    enterDirectory(routeAdd) {
      this.filesPrev = [];
      //this.route = routeAdd.name;
      this.folder_id = routeAdd.folder_id;
      this.route = routeAdd.name;
      this.type = routeAdd.type;

      this.fetchCurrentRoute();
    },
    fetchConsents() {
      this.getConsents({
        filters: {
          from: this.from,
          to: this.to,
          customer_id: this.customer_id,
          tattooer_id: this.tattooer_id,
          state: this.state,
        },
      }).then((data) => {
        this.save(data);
      });
    },
    exportConsents() {
      this.getExportConsents({
        filters: {
          from: this.from,
          to: this.to,
          customer_id: this.customer_id,
        },
      }).then((data) => {
        console.log(data);
        window.open(data.url, "_blank");
      });
    },
    switchConsents() {
      this.consents = !this.consents;
      if (this.consents) {
        this.fetchConsents();
      } else {
        this.fetchCurrentRoute();
      }
    },
    save(data) {
      this.route = data.actualRoute;
      this.directories = data.directories;
      this.files = data.files;
      this.isBase = data.isBase;
      this.selected = [];
      this.search = null;
      if (data.folder) this.folders.push(data.folder);
    },
    backDirectory() {
      this.goTo(this.folders.length - 2);
    },
    goTo(index) {
      let goTo = this.folders[index];
      console.log(goTo);
      this.folder_id = goTo.id;
      this.route = goTo.route;
      this.type = goTo.id ? "internal" : "s3";
      this.folders.splice(index);
      this.fetchCurrentRoute();
    },

    clearName(name) {
      ////console.log("CLEAR", name);
      //console.log("clear");
      name = name.name.split("/");
      return name[name.length - 1];
    },
    nameAvatar(name) {
      //console.log("AVATAR");
      // //console.log("AVATAR", name);
      let array = name.name.split("/");

      let arr = array[array.length - 1];
      //console.log(arr);
      arr = arr.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });
      // //console.log(nom);
      return nom;
    },

    viewFile(fileName) {
      this.getSelectedFile({ filters: { selected: fileName.name } }).then(
        (data) => {
          if (data.success) {
            this.fileDialog = true;
            this.selectedFile = data.url;
            this.currentPage = 1;
          }
        }
      );
    },
    fileType(filename) {
      // //console.log("type", filename);
      //console.log("type");
      if (typeof filename === "object") filename = filename.name;
      filename = filename.split(".");
      let type = filename[filename.length - 1];
      switch (type.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
          return "img";
        case "pdf":
          return "pdf";
        case "docx":
        case "pptx":
        case "xlsx":
          return "office";

        case "txt":
          return "text";
        case "zip":
        case "rar":
          return "zip";
        default:
          return type;
      }
    },
    del(files) {
      this.$confirm(this.$t("are_you_sure"), "", "").then(() => {
        this.filesPrev = [];
        // //console.log("sel", this.selected);
        this.delSelected({
          files,
          s3_dir: this.route,
          type: this.type,
          folder_id: this.folder_id,
        }).then((data) => {
          this.save(data);
        });
      });
    },
    rename(file) {
      //console.log(file);
      this.$refs.rename.open(file);
    },
    move(file) {
      this.filesPrev = [];
      this.$refs.moveFile.open(file);
    },
    copy(file) {
      this.filesPrev = [];
      this.$refs.copyFile.copy(file);
    },

    ...mapActions("customers", ["getCustomers"]),
    fetchCustomers() {
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
      }).then((response) => {
        let cs = response.data;
        this.customers = cs;
        this.isLoadingCustomer = false;
      });
    },
    ...mapActions("studios", ["getStudioTattooersByDate"]),
    fetchTattooers() {
      this.getStudioTattooersByDate({
        studio_id: this.$store.state.auth.user.studio.id,
      }).then((response) => {
        //console.log(response);
        //console.log(this.appointment.type);

        this.tattooers = response.map((t) => t.tattooer);
      });
    },

    searchFilter: debounce(function(val) {
      this.fetchConsents();
    }, 1000),
  },
  mounted() {
    if (this.$route.params.sub_route) {
      this.auto_enter = this.$route.params.sub_route;
      this.backAppointment = true;
    }

    this.fetchCurrentRoute();
    this.fetchCustomers();
    if (this.$store.getters["auth/isStudio"]) this.fetchTattooers();
  },
  computed: {
    directoriesFiltered() {
      if (this.search && this.directories.length)
        return this.directories.filter((d) => {
          console.log(d.name);
          console.log(d.name.includes(this.search));
          return this.clearName(d)
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      return this.directories;
    },
    filesFiltered() {
      if (this.search && this.files.length)
        return this.files.filter((f) =>
          this.clearName(f)
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      return this.files;
    },
  },
};
</script>
<style lang="sass" scoped>

.v-dialog--active
  max-width: 500px !important
.v-data-table>.v-data-table__wrapper>table
  border-spacing: 10px !important
/*.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
 background-color: transparent !important*/
.color
  background-color: var(--v-primary-base) !important
.center
  display: flex
  justify-content: center

.fileList
  height: 30px
.v-btn--fab.v-size--default
  height: 40px
  width: 40px

.v-input--selection-controls__input .v-icon
  color: var(--v-primary-base) !important
.fileList .v-icon, .fileList .svg-icon
  color: var(--v-gris1-lighten2)
.internal-btn
  position: absolute
  right: -15px
</style>

<style lang="sass">
.pdf_preview
  max-height: 150px
  canvas
    width: auto !important
    max-width: 100%
    max-height: 140px

.v-chip
  .v-chip__content
    font-size: 12px
    color: white
</style>
